import * as React from "react";

import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSHeading, EGDSSubheading } from "@egds/react-core/text";

import { SuperscriptSmallCharacters } from "components/shared/SuperscriptSpecialCharacters/SuperscriptSmallCharacters";
import { HotwireDealsTitleProps } from "components/flexComponents/HotwireDeals/typings";

export const HotwireDealsTitle = (hotwireDealsTitleProps: HotwireDealsTitleProps) => {
  const { title, subtitle } = hotwireDealsTitleProps;

  return (
    <EGDSSpacing margin={{ blockend: "four" }}>
      <div>
        <EGDSHeading tag="h2" size={4}>
          <SuperscriptSmallCharacters>{title}</SuperscriptSmallCharacters>
        </EGDSHeading>
        {subtitle && (
          <EGDSSpacing margin={{ blockstart: "one" }}>
            <EGDSSubheading tag="h3">
              <SuperscriptSmallCharacters>{subtitle}</SuperscriptSmallCharacters>
            </EGDSSubheading>
          </EGDSSpacing>
        )}
      </div>
    </EGDSSpacing>
  );
};
