import * as React from "react";

import { useLocalization } from "@shared-ui/localization-context";
import { EGDSBadge } from "@egds/react-core/badge";
import { EGDSLayoutPosition } from "@egds/react-core/layout-position";

interface HotwireDealsCardDiscountBadgeProps {
  useNegativePosition?: boolean;
  hasStrikeThrough: boolean;
  percentOff?: string;
}

export const HotwireDealsCardDiscountBadge: React.FC<HotwireDealsCardDiscountBadgeProps> = ({
  useNegativePosition = false,
  hasStrikeThrough,
  percentOff,
}) => {
  const { formatText } = useLocalization();

  if (!hasStrikeThrough) {
    return null;
  }

  const badge = <EGDSBadge text={formatText("hotwire.hotwire-deals.percentOffBadgeText", percentOff)} />;

  return useNegativePosition ? (
    <EGDSLayoutPosition type="absolute" position={{ top: "minus-six", right: "zero" }} cloneElement>
      {badge}
    </EGDSLayoutPosition>
  ) : (
    badge
  );
};
