import * as React from "react";

import { EGDSCard, EGDSCardContentSection, EGDSCardFeaturedHeader, EGDSCardLink } from "@egds/react-core/cards";
import { useLocalization } from "@shared-ui/localization-context";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSLayoutPosition } from "@egds/react-core/layout-position";
import { EGDSSpacing } from "@egds/react-core/spacing";

import { HotwireDealsType } from "../../utils/HotwireDealsConstants";
import { HotwireCarDealsCardContent } from "./HotwireCarDealsCardContent";
import { HotwireDealsCardPrice } from "./HotwireDealsCardPrice";
import { HotwireDealsCardDiscountBadge } from "./HotwireDealsCardDiscountBadge";

import { HotwireCarDealsCardProps } from "../../typings";

export const HotwireCarDealsCard: React.FC<HotwireCarDealsCardProps> = ({
  deal: {
    topDeal,
    vendorLogoUrl,
    car: { type, model, imageUrl, seating },
    price: { formattedDisplayPrice, formattedPercentOff, formattedStrikeThroughPrice, hasStrikeThrough },
    url,
  },
  dataTestId,
  dealsType,
  context,
}) => {
  const { formatText } = useLocalization();
  const a11yLabel = `${type} ${model}`;
  const isOpaque = dealsType === HotwireDealsType.OPAQUE;

  return (
    <EGDSLayoutGridItem>
      <EGDSCard className={`HotwireDeals--${isOpaque ? "Default" : "Retail"}DealsCard`}>
        <EGDSCardLink>
          <a data-testid={dataTestId} href={url}>
            {a11yLabel}
          </a>
        </EGDSCardLink>
        {topDeal && isOpaque && (
          <EGDSCardFeaturedHeader title={{ tag: "h3", text: formatText("hotwire.hotwire-deals.topDealHeader") }} />
        )}
        <EGDSSpacing
          margin={{
            blockstart: "three",
            inlinestart: "three",
          }}
        >
          <img
            className={`car-deal-img ${!topDeal && isOpaque ? "car-deal-img-no-td" : ""}`}
            src={imageUrl}
            alt={a11yLabel}
          />
        </EGDSSpacing>
        <EGDSCardContentSection>
          <EGDSLayoutFlex space="two">
            <>
              <EGDSLayoutFlexItem grow={1}>
                <div>
                  <HotwireCarDealsCardContent
                    header={type}
                    subHeader={model}
                    people={seating.total}
                    vendorLogoUrl={vendorLogoUrl}
                  />
                </div>
              </EGDSLayoutFlexItem>
              <EGDSLayoutFlexItem shrink={1} alignSelf={!isOpaque ? "end" : undefined}>
                <EGDSLayoutPosition type="relative" tag="div">
                  <HotwireDealsCardDiscountBadge hasStrikeThrough={hasStrikeThrough} percentOff={formattedPercentOff} />
                  <HotwireDealsCardPrice
                    hasStrikeThrough={hasStrikeThrough}
                    displayPrice={formattedDisplayPrice}
                    strikeThroughPrice={formattedStrikeThroughPrice}
                    marginTop="one"
                    isCarDeal
                  />
                </EGDSLayoutPosition>
              </EGDSLayoutFlexItem>
            </>
          </EGDSLayoutFlex>
        </EGDSCardContentSection>
      </EGDSCard>
    </EGDSLayoutGridItem>
  );
};
