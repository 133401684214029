import * as React from "react";
import {
  HotwireDealsHotelFlexModuleResult,
  HotwireDealsCarFlexModuleResult,
} from "typings/microserviceModels/hotwire-deals-flex-module";
import { CompositionStore } from "stores/CompositionStore";
import { FlexViewModelStore } from "stores/flexViewModel/FlexViewModelStore";

/**
 * How many digits to keep for the lat/lon numbers. 5 digits is more than enough for our use case:
 * https://en.wikipedia.org/wiki/Decimal_degrees#Precision
 */
const PRECISION = 5;
const LAT = "lat";
const LON = "lon";

/**
 * Hook which calls the browser's geolocation API and reloads the page with new lat/lon param.
 * Only happens if the lat/lon is not already present in the URL, askForGeolocation is set to true and ajaxOnly is not.
 * Note: The hook needs to execute regardless of whether the module returned deals or not.
 */
export const useGeolocationReload = (
  compositionStore: CompositionStore,
  fmId: number,
  model: HotwireDealsHotelFlexModuleResult | HotwireDealsCarFlexModuleResult | null,
  flexViewModel: FlexViewModelStore
) => {
  const [isLoading, setLoading] = React.useState(false);
  const ajaxOnly = (model as HotwireDealsHotelFlexModuleResult)?.ajaxOnly;

  React.useEffect(() => {
    if (model) {
      if (ajaxOnly && !isLoading) {
        // AJAX use case: Reload model only for this module
        if (compositionStore) {
          setLoading(true);
          navigator.geolocation.getCurrentPosition(
            (position) => {
              compositionStore
                .updateModule({
                  fmId,
                  lat: Number(position.coords.latitude.toFixed(PRECISION)),
                  lon: Number(position.coords.longitude.toFixed(PRECISION)),
                })
                .finally(() => setLoading(false));
            },
            () => {
              compositionStore
                .updateModule({
                  fmId,
                  lat: flexViewModel?.userLocation?.latitude ?? 0,
                  lon: flexViewModel?.userLocation?.longitude ?? 0,
                })
                .finally(() => setLoading(false));
            }
          );
        }
      } else if (model.askForGeolocation && flexViewModel.trackingContext.lineOfBusiness === "hotels-near") {
        // Hotels-Near use case: Reload full page
        const searchParams = new URLSearchParams(window.location.search);
        if (!searchParams.has(LAT) && !searchParams.has(LON)) {
          navigator.geolocation.getCurrentPosition((position) => {
            searchParams.set(LAT, position.coords.latitude.toFixed(PRECISION));
            searchParams.set(LON, position.coords.longitude.toFixed(PRECISION));
            window.location.search = searchParams.toString();
          });
        }
      }
    }
  }, [compositionStore, model, ajaxOnly, fmId]);

  return isLoading;
};
