import * as React from "react";

import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { EGDSSubheading, EGDSHeading, EGDSText } from "@egds/react-core/text";
import { EGDSSpacing } from "@egds/react-core/spacing";

interface HotwireHotelDealsCardContentProps {
  header: string;
  subHeader: string;
  distance?: string;
  scrimContainer?: boolean;
}

export const HotwireHotelDealsCardContent: React.FC<HotwireHotelDealsCardContentProps> = ({
  header,
  subHeader,
  distance,
  scrimContainer,
}) => (
  <>
    {scrimContainer ? (
      <EGDSSubheading tag="h4" overflow="truncate">
        {header}
      </EGDSSubheading>
    ) : (
      <EGDSHeading tag="h4" size={5} overflow="truncate">
        {header}
      </EGDSHeading>
    )}
    <EGDSText size={200} overflow="truncate-2-lines">
      {subHeader}
    </EGDSText>
    {distance && (
      <div>
        <EGDSIcon name="place" size={EGDSIconSize.EXTRA_SMALL} />
        <EGDSSpacing margin={{ inlinestart: "one" }}>
          <EGDSText inline size={200} overflow="truncate">
            {distance}
          </EGDSText>
        </EGDSSpacing>
      </div>
    )}
  </>
);
