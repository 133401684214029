import * as React from "react";

import { EGDSLayoutGrid } from "@egds/react-core/layout-grid";

import { HotwireShowMoreDealsLink } from "./components/HotwireShowMoreDealsLink";
import { HotwireShowMoreDealsCard } from "./components/HotwireShowMoreDealsCard";
import { HotwireNearbyDealsCard } from "./components/DealsCard/HotwireNearbyDealsCard";
import { HotwireDefaultDealsCard } from "./components/DealsCard/HotwireDefaultDealsCard";
import { HotwireCarDealsCard } from "./components/DealsCard/HotwireCarDealsCard";
import { HotwireDealsViews } from "./utils/HotwireDealsViews";
import { HotwireDealsFor } from "./utils/HotwireDealsConstants";

import { HotwireDealsProps } from "./typings";
import { withStores } from "stores";
import {
  HotwireCarDeal,
  HotwireDealsHotelFlexModuleResult,
  HotwireDealsCarFlexModuleResult,
  HotwireHotelDeal,
} from "typings/microserviceModels/hotwire-deals-flex-module";
import { observer } from "mobx-react";
import { useGeolocationReload } from "components/flexComponents/HotwireDeals/utils/useGeolocationReload";
import { LazyLoad } from "components/utility/LazyLoad/LazyLoad";
import { HotwireDealsPlaceholder } from "components/flexComponents/HotwireDeals/HotwireDealsPlaceHolder";
import { HotwireDealsTitle } from "components/flexComponents/HotwireDeals/HotwireDealsTitle";

export const HotwireDeals = withStores(
  "compositionStore",
  "flexModuleModelStore",
  "flexViewModel"
)(
  observer((props: HotwireDealsProps) => {
    const { context, compositionStore, templateComponent, flexModuleModelStore, flexViewModel } = props;
    const {
      metadata: { id },
      config,
    } = templateComponent;
    const fmId = config.fmId || 0;
    const model = flexModuleModelStore.getModel(id) as
      | HotwireDealsHotelFlexModuleResult
      | HotwireDealsCarFlexModuleResult;
    const moduleIndex = id.replace("hotwire-deals-", "");

    const carModel = model as HotwireDealsCarFlexModuleResult | null;
    const hotelModel = model as HotwireDealsHotelFlexModuleResult | null;
    const dealsFor = carModel?.dealsFor || undefined;
    const dealsType = carModel?.dealsType || undefined;
    const isDealForCar = dealsFor === HotwireDealsFor.CAR;
    const deals = isDealForCar ? carModel?.deals : hotelModel?.deals;

    const hwAppSearchUrl = model?.searchUrl || "";
    const isLoading = useGeolocationReload(compositionStore, fmId, model, flexViewModel);

    if (isLoading) {
      return (
        <>
          <HotwireDealsTitle title={model?.title} subtitle={model?.subtitle} />
          <HotwireDealsPlaceholder />
        </>
      );
    }

    if (!model || model.empty || model.limit < 1 || !model.deals || model.deals.length < 1) {
      return null;
    }

    const { hasMoreDealsCard, seeAllDealsLinkLabel, subtitle, title, view } = model;

    return (
      <LazyLoad context={context}>
        <div
          className="HotwireDeals"
          id={id}
          data-fm={config.fmId}
          data-fm-title-id={config.fmTitleId}
          data-module="content-area"
        >
          <HotwireDealsTitle title={title} subtitle={subtitle} />
          <EGDSLayoutGrid columns={{ small: 1, medium: 2, large: 4, extra_large: 4 }} space="three">
            <>
              {isDealForCar
                ? (deals as HotwireCarDeal[]).map((deal: HotwireCarDeal, index: number) => {
                    const CardComponent = HotwireCarDealsCard;

                    return (
                      <CardComponent
                        key={`hotwire-deals_${index}`}
                        deal={deal}
                        dataTestId={`deals-module-${moduleIndex}-card-${index + 1}`}
                        context={context}
                        dealsType={dealsType}
                      />
                    );
                  })
                : (deals as HotwireHotelDeal[]).map((deal: HotwireHotelDeal, index: number) => {
                    const CardComponent =
                      view === HotwireDealsViews.NEARBY ? HotwireNearbyDealsCard : HotwireDefaultDealsCard;

                    const isViewNearby = view === HotwireDealsViews.NEARBY;

                    return (
                      <CardComponent
                        key={`hotwire-deals_${index}`}
                        deal={deal}
                        dataTestId={`deals-module-${moduleIndex}-card-${index + 1}`}
                        context={context}
                        showCardTopBanner={!isViewNearby}
                      />
                    );
                  })}
              {hasMoreDealsCard && (
                <HotwireShowMoreDealsCard
                  key="hotwire-deals_show-more-card"
                  searchUrl={hwAppSearchUrl}
                  dataTestId={`deals-module-${moduleIndex}-show-more-deals`}
                  dealsFor={dealsFor}
                />
              )}
            </>
          </EGDSLayoutGrid>
          {(view === HotwireDealsViews.DEFAULT || isDealForCar) && (
            <HotwireShowMoreDealsLink
              searchUrl={hwAppSearchUrl}
              seeAllDealsLabel={seeAllDealsLinkLabel}
              dataTestId={`deals-module-${moduleIndex}-show-more-deals`}
            />
          )}
        </div>
      </LazyLoad>
    );
  })
);

export default HotwireDeals;
