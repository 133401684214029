import * as React from "react";

import { useLocalization } from "@shared-ui/localization-context";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { PriceLockup } from "components/shared/PriceLockup/PriceLockup";
import { PriceLockupAlign } from "components/shared/PriceLockup/typings";

interface HotwireDealsCardPriceProps {
  hasStrikeThrough: boolean;
  displayPrice: string;
  strikeThroughPrice?: string;
  marginTop?: "one" | "two";
  isCarDeal?: boolean;
}

export const HotwireDealsCardPrice: React.FC<HotwireDealsCardPriceProps> = ({
  hasStrikeThrough,
  displayPrice,
  strikeThroughPrice,
  marginTop = "two",
  isCarDeal = false,
}) => {
  const { formatText } = useLocalization();
  const carLocPrefix = isCarDeal ? "car." : "";
  const priceA11yLabel = hasStrikeThrough
    ? formatText(`hotwire.hotwire-deals.${carLocPrefix}strikethroughPriceA11yLabel`, strikeThroughPrice, displayPrice)
    : formatText(`hotwire.hotwire-deals.${carLocPrefix}priceA11yLabel`, displayPrice);
  const displayedStrikethrough = hasStrikeThrough
    ? formatText("hotwire.hotwire-deals.strikethroughPriceDisplay", strikeThroughPrice)
    : undefined;
  const priceSubtextFirst = formatText(`hotwire.hotwire-deals.${carLocPrefix}priceSubtext`);

  return (
    <EGDSSpacing margin={{ blockstart: marginTop }}>
      <PriceLockup
        lockupPrice={displayPrice}
        strikeThroughPrice={displayedStrikethrough}
        priceSubtextFirst={priceSubtextFirst}
        a11yPrice={priceA11yLabel}
        align={PriceLockupAlign.LEFT}
      />
    </EGDSSpacing>
  );
};
