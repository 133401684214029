import * as React from "react";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSCard } from "@egds/react-core/cards";
import { EGDSFigure, EGDSFigureAspectRatioType } from "@egds/react-core/images";
import { EGDSBlockSkeleton } from "@egds/react-core/skeleton";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";

export const HotwireDealsPlaceholder = () => {
  const keyHelper = new ItemKeyHelper(`placeholder-LayoutGrid`);
  return (
    <EGDSLayoutGrid columns={{ medium: 2, extra_large: 4, small: 1, large: 4 }} space="three">
      {[...Array(4)].map(() => (
        <EGDSLayoutGridItem key={keyHelper.next()}>
          <EGDSCard>
            <EGDSFigure ratio={EGDSFigureAspectRatioType.R3_2} className="uitk-card-roundcorner-all">
              <EGDSBlockSkeleton animation />
            </EGDSFigure>
          </EGDSCard>
        </EGDSLayoutGridItem>
      ))}
    </EGDSLayoutGrid>
  );
};
