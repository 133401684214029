import * as React from "react";

import { useLocalization } from "@shared-ui/localization-context";
import { EGDSCard, EGDSCardContentSection, EGDSCardFeaturedHeader, EGDSCardLink } from "@egds/react-core/cards";
import { EGDSFigure, EGDSFigureAspectRatioType, EGDSImage } from "@egds/react-core/images";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSLayoutPosition } from "@egds/react-core/layout-position";
import { EGDSRating } from "@egds/react-core/rating";
import { EGDSText } from "@egds/react-core/text";

import { HotwireHotelDealsCardContent } from "./HotwireHotelDealsCardContent";
import { HotwireDealsCardPrice } from "./HotwireDealsCardPrice";
import { HotwireDealsCardDiscountBadge } from "./HotwireDealsCardDiscountBadge";

import { HotwireHotelDealsCardProps } from "../../typings";

export const HotwireDefaultDealsCard: React.FC<HotwireHotelDealsCardProps> = ({
  deal: {
    formattedDistanceFromSearchLocation,
    formattedLocation,
    formattedStarRating,
    imageUrl,
    name,
    popularChoice,
    price: {
      formattedDisplayPrice,
      formattedPercentOff,
      formattedStrikeThroughPrice,
      hasStrikeThrough,
      formattedTotalCost,
    },
    url,
  },
  showCardTopBanner = true,
  dataTestId,
  context,
}) => {
  const { formatText } = useLocalization();
  const a11yLabel = `${name} ${formattedLocation}`;
  const priceSubtextFirst = formatText("hotwire.hotwire-deals.priceSubtext");
  const taxesText = formatText("hotwire.hotwire-deals.taxesSubtext");
  const totalText = formatText("hotwire.hotwire-deals.totalpriceSubtext");

  const insideCard = (
    <EGDSCard className="HotwireDeals--DefaultDealsCard">
      <EGDSCardLink>
        <a data-testid={dataTestId} href={url}>
          {a11yLabel}
        </a>
      </EGDSCardLink>
      {showCardTopBanner && (
        <EGDSCardFeaturedHeader
          title={{ tag: "h3", text: formatText("hotwire.hotwire-deals.hotrateDealCardHeader") }}
        />
      )}
      <EGDSLayoutPosition type="relative">
        <EGDSFigure ratio={showCardTopBanner ? EGDSFigureAspectRatioType.R21_9 : EGDSFigureAspectRatioType.R16_9}>
          <EGDSImage src={imageUrl} alt={a11yLabel} />
        </EGDSFigure>
      </EGDSLayoutPosition>
      <EGDSCardContentSection>
        <EGDSLayoutFlex space="two">
          <>
            <EGDSLayoutFlexItem grow={1}>
              <div>
                <EGDSRating rating={formattedStarRating} />
                <HotwireHotelDealsCardContent
                  header={name}
                  subHeader={formattedLocation}
                  distance={formattedDistanceFromSearchLocation}
                />
              </div>
            </EGDSLayoutFlexItem>
            <EGDSLayoutFlexItem>
              <EGDSLayoutPosition type="relative" tag="div">
                {typeof formattedTotalCost === "undefined" ? (
                  <>
                    <HotwireDealsCardDiscountBadge
                      useNegativePosition
                      hasStrikeThrough={hasStrikeThrough}
                      percentOff={formattedPercentOff}
                    />
                    <HotwireDealsCardPrice
                      hasStrikeThrough={hasStrikeThrough}
                      displayPrice={formattedDisplayPrice}
                      strikeThroughPrice={formattedStrikeThroughPrice}
                    />
                  </>
                ) : (
                  <>
                    <EGDSText inline size={700} weight="bold">
                      {formattedDisplayPrice}
                    </EGDSText>
                    <EGDSText size={100}>{priceSubtextFirst}</EGDSText>
                    <EGDSText size={300}>
                      {formattedTotalCost}{" "}
                      <EGDSText inline size={100}>
                        {totalText}
                      </EGDSText>
                    </EGDSText>
                    <EGDSText size={100}>{taxesText}</EGDSText>
                  </>
                )}
              </EGDSLayoutPosition>
            </EGDSLayoutFlexItem>
          </>
        </EGDSLayoutFlex>
      </EGDSCardContentSection>
    </EGDSCard>
  );

  return (
    <EGDSLayoutGridItem>
      <>{insideCard}</>
    </EGDSLayoutGridItem>
  );
};
