import * as React from "react";

import { useLocalization, LocalizedText } from "@shared-ui/localization-context";
import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { EGDSSpacing } from "@egds/react-core/spacing";

interface HotwireCarDealsCardContentProps {
  header: string;
  subHeader: string;
  people: number;
  vendorLogoUrl: string;
}

export const HotwireCarDealsCardContent: React.FC<HotwireCarDealsCardContentProps> = ({
  header,
  subHeader,
  people,
  vendorLogoUrl,
}) => {
  const { formatText } = useLocalization();

  return (
    <>
      <EGDSHeading tag="h4" size={5} overflow="truncate">
        {header}
      </EGDSHeading>
      <EGDSSpacing margin={{ blockstart: "half" }}>
        <EGDSText size={200} overflow="truncate">
          {subHeader}
        </EGDSText>
      </EGDSSpacing>
      <EGDSSpacing margin={{ blockstart: "one", blockend: "half" }}>
        <div>
          <EGDSIcon name="person" size={EGDSIconSize.EXTRA_SMALL} />
          <EGDSSpacing margin={{ inlinestart: "one" }}>
            <EGDSText inline size={200} overflow="truncate">
              <LocalizedText message="hotwire.hotwire-deals.car.carCapacity" data={[people]} />
            </EGDSText>
          </EGDSSpacing>
        </div>
      </EGDSSpacing>
      {vendorLogoUrl && (
        <EGDSSpacing margin={{ blockstart: "one" }}>
          <img
            className="car-vendor-logo"
            alt={formatText("hotwire.hotwire-deals.car.vendorLogoAltText")}
            src={vendorLogoUrl}
          />
        </EGDSSpacing>
      )}
    </>
  );
};
