import * as React from "react";

import { EGDSCard, EGDSCardLink } from "@egds/react-core/cards";
import { EGDSFigure, EGDSFigureAspectRatioType, EGDSImage } from "@egds/react-core/images";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSScrim, EGDSScrimType } from "@egds/react-core/scrim";

import { HotwireHotelDealsCardContent } from "./HotwireHotelDealsCardContent";
import { HotwireDealsCardPrice } from "./HotwireDealsCardPrice";
import { HotwireDealsCardDiscountBadge } from "./HotwireDealsCardDiscountBadge";

import { HotwireHotelDealsCardProps } from "../../typings";

export const HotwireNearbyDealsCard: React.FC<HotwireHotelDealsCardProps> = ({
  deal: {
    formattedDistanceFromSearchLocation,
    formattedLocation,
    imageUrl,
    name,
    price: { formattedDisplayPrice, formattedPercentOff, formattedStrikeThroughPrice, hasStrikeThrough },
    url,
  },
  dataTestId,
  context,
}) => {
  const a11yLabel = `${name} ${formattedLocation}`;

  return (
    <EGDSLayoutGridItem>
      <EGDSCard className="HotwireDeals--NearbyDealsCard">
        <EGDSCardLink>
          <a data-testid={dataTestId} href={url}>
            {a11yLabel}
          </a>
        </EGDSCardLink>
        <EGDSFigure ratio={EGDSFigureAspectRatioType.R3_2}>
          <EGDSImage src={imageUrl} alt={a11yLabel} />
          <EGDSScrim type={EGDSScrimType.BOTTOM}>
            <EGDSLayoutFlex space="two">
              <>
                <EGDSLayoutFlexItem grow={1}>
                  <EGDSLayoutFlex direction="column" justifyContent="end">
                    <HotwireHotelDealsCardContent
                      header={formattedLocation}
                      subHeader={name}
                      distance={formattedDistanceFromSearchLocation}
                      scrimContainer
                    />
                  </EGDSLayoutFlex>
                </EGDSLayoutFlexItem>
                <EGDSLayoutFlexItem shrink={1}>
                  <div>
                    <HotwireDealsCardDiscountBadge
                      useNegativePosition={false}
                      hasStrikeThrough={hasStrikeThrough}
                      percentOff={formattedPercentOff}
                    />
                    <HotwireDealsCardPrice
                      hasStrikeThrough={hasStrikeThrough}
                      displayPrice={formattedDisplayPrice}
                      strikeThroughPrice={formattedStrikeThroughPrice}
                    />
                  </div>
                </EGDSLayoutFlexItem>
              </>
            </EGDSLayoutFlex>
          </EGDSScrim>
        </EGDSFigure>
      </EGDSCard>
    </EGDSLayoutGridItem>
  );
};
