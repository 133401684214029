import * as React from "react";

import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSText } from "@egds/react-core/text";

import { ShowMoreDealsProps, ShowMoreDealsTextProps } from "../typings";

const ShowMoreDealsText: React.FC<ShowMoreDealsTextProps> = ({ align, searchUrl, dataTestId, seeAllDealsLabel }) => (
  <EGDSText size={500} weight="bold" align={align}>
    <a href={searchUrl} data-testid={dataTestId}>
      {seeAllDealsLabel}
    </a>
  </EGDSText>
);

export const HotwireShowMoreDealsLink: React.FC<ShowMoreDealsProps> = (props) => (
  <EGDSSpacing padding={{ blockstart: "three" }}>
    <div>
      <Viewport>
        <ViewSmall>
          <ShowMoreDealsText align="center" {...props} />
        </ViewSmall>
        <ViewMedium>
          <ShowMoreDealsText align="left" {...props} />
        </ViewMedium>
      </Viewport>
    </div>
  </EGDSSpacing>
);
