import * as React from "react";

import { useLocalization } from "@shared-ui/localization-context";
import { EGDSButtonSize, EGDSSecondaryButton } from "@egds/react-core/button";
import { EGDSCard } from "@egds/react-core/cards";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSText } from "@egds/react-core/text";

import { HotwireDealsFor } from "../utils/HotwireDealsConstants";

interface ShowMoreDealsCardProps {
  dataTestId: string;
  searchUrl: string;
  dealsFor?: string;
}

export const HotwireShowMoreDealsCard: React.FC<ShowMoreDealsCardProps> = ({ dataTestId, searchUrl, dealsFor }) => {
  const { formatText } = useLocalization();

  const isCarDeal = dealsFor === HotwireDealsFor.CAR;
  const carLocPrefix = isCarDeal ? "car." : "";
  const seeMoreDealsLine1 = formatText("hotwire.hotwire-deals.seeMoreDealsLine1");
  const seeMoreDealsLine2 = formatText(`hotwire.hotwire-deals.${carLocPrefix}seeMoreDealsLine2`);
  const showMoreDealsCardText = formatText(`hotwire.hotwire-deals.${carLocPrefix}showMoreDealsCardText`);

  return (
    <EGDSLayoutGridItem>
      <EGDSCard className="HotwireDeals--ShowMoreDealsCard">
        <EGDSSpacing padding={{ small: { block: "twelve" }, medium: { block: "three" } }}>
          <EGDSLayoutFlex
            className="HotwireDeals--ShowMoreDealsCard--Content"
            direction="column"
            alignContent="center"
            alignItems="center"
            justifyContent="center"
          >
            <EGDSLayoutFlexItem>
              <EGDSText
                className="HotwireDeals--ShowMoreDealsCard--Content--Text"
                size={500}
                weight="bold"
                align="center"
              >
                {seeMoreDealsLine1}
                <br />
                {seeMoreDealsLine2}
              </EGDSText>
            </EGDSLayoutFlexItem>
            <EGDSLayoutFlexItem>
              <EGDSSpacing margin={{ blockstart: "four" }}>
                <EGDSSecondaryButton size={EGDSButtonSize.LARGE} tag="a" href={searchUrl} data-testid={dataTestId}>
                  {showMoreDealsCardText}
                </EGDSSecondaryButton>
              </EGDSSpacing>
            </EGDSLayoutFlexItem>
          </EGDSLayoutFlex>
        </EGDSSpacing>
      </EGDSCard>
    </EGDSLayoutGridItem>
  );
};
